import { afterNextRender, Component, inject, signal, WritableSignal } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { unableOperationMessage } from '../../shared/common/utils';
import { ModalContentService } from '../../shared/modal-content/modal-content.service';

@Component({
  selector: 'app-ecommerce-admin',
  standalone: true,
  imports: [],
  templateUrl: './ecommerce-admin.component.html',
  styleUrl: './ecommerce-admin.component.scss'
})
export class EcommerceAdminComponent {

  #authService = inject(AuthService);
  #route = inject(ActivatedRoute);
  #router = inject(Router);
  #modalContentService = inject(ModalContentService);

  isFinished: WritableSignal<boolean> = signal(false);
  isError: WritableSignal<boolean> = signal(false);
  isSuccess: WritableSignal<boolean> = signal(false);

  constructor() {
    afterNextRender(() => this.#handleTokenFromUrl())
  }

  #handleTokenFromUrl() {
    const token = this.#route.snapshot.paramMap.get('token');
    if (!token) {
      unableOperationMessage(this.#modalContentService);
      setTimeout(() => {
        this.#router.navigate(['/']);
      }, 1000 * 3);
      return;
    } else {
      this.#authService.loginAsAdmin(token).subscribe();
    }

  }

}
