<div class="d-flex flex-column justify-content-center align-items-center vh-100 px-4">
    <img src="assets/images/logo-main.svg" alt="Company Logo" class="mb-4" style="width: 150px;" />
    <p class="text-center mt-3 mb-0 text-primary h3-semibold">Welcome to Mother Earth Food</p>
    <p class="text-center mt-3 text-secondary h4-semibold mb-0">
        You are signing in as an administrator. You will be redirected to your eCommerce platform shortly.
    </p>
    <span class="material-icons md-48 text-primary loading-icon my-3">autorenew</span>
</div>

<!-- Footer "Powered by Navego" -->
<footer class="footer fixed-bottom text-center py-2">
    <small class="text-muted">
        Powered by <strong>Navego</strong>
    </small>
</footer>