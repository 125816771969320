<div class="signup">
  <div class="signup__header animated fadeInDown">
    <div class="container my-3">
      <a routerLink="/shop">
        <img class="signup__logo" src="/assets/images/logo.svg?v={{logoVersionNumber()}}" alt="Mother Earth">
      </a>
    </div>
  </div>

  @if (isLoadingContent() || accountService.isLoading() || isWaitingResponse()) {
  <mat-progress-bar mode="indeterminate" value="accent"></mat-progress-bar>
  }

  <div class="signup__body animated fadeInUp">
    <h2 class="h2-semibold d-flex justify-content-center">Create your account</h2>
    <div class="container-fluid">

      <mat-stepper [linear]="true" #stepper class="animated fadeIn">

        <!-- Delivery information -->
        <mat-step #firstStep [editable]="false" label="Delivery Address">
          <ng-template matStepperIcon="edit"> 1 </ng-template>
          <app-check-address-signup [firstStep]="firstStep"
            (deliveryAddressChanged)="deliveryAddressInformation = $event"
            (selectedDayChanged)="deliverySelectedDay = $event"
            (selectedTimeWindowChanged)="deliverySelectedTimeWindow = $event"
            (deliveryOptionChanged)="deliveryOptionSelected = $event" (onNextStep)="continue()">
          </app-check-address-signup>
        </mat-step>

        <!-- Personal information -->
        <mat-step [completed]="formValidityPersonalInformation && firstStep.completed" [editable]="false"
          label="Personal Information">
          <app-personal-information-signup [stepper]="matStepper" [firstStep]="firstStep"
            (formValidityChanged)="formValidityPersonalInformation = $event"
            (personalInformationChanged)="personalInformationData = $event" (onNextStep)="createNewClient()">
          </app-personal-information-signup>
        </mat-step>

        <!-- Preferences validation -->
        <mat-step [completed]="formValidityShoppingPreferences && firstStep.completed" [editable]="false"
          label="Shopping preferences">
          <div class="animated fadeIn">
            <app-preferences-validation-signup [stepper]="matStepper" [firstStep]="firstStep"
              [dietaryRestrictions]="personalInformation()?.dietaryRestrictions"
              [peopleOptions]="personalInformation()?.peopleShoppingFor"
              (formValidityChanged)="formValidityShoppingPreferences = $event" (shopforChanged)="shopforValue = $event"
              (dietaryChanged)="dietaryRestrictionsValues = $event" (onNextStep)="updateCreatedClient()">
            </app-preferences-validation-signup>
          </div>
        </mat-step>

        <!-- Payment method information -->
        <mat-step [completed]="skipPaymentMethodStep || (formValidityPaymentMethod && !isWaitingResponse())"
          [editable]="false" [stepControl]="paymentMethodStepControl" label="Payment Method">
          <div>
            <app-payment-method-signup (formValidityChanged)="formValidityPaymentMethod = $event"
              (onNextStep)="continue(true)" [in_selectedMembership]="deliveryOptionSelected">
            </app-payment-method-signup>
          </div>
        </mat-step>

        <!-- Bundle subscriptions -->
        <mat-step [editable]="false" label="Subscriptions">
          <div>
            <app-subscriptions-signup [subscriptionCard]="subscriptionCard()">
            </app-subscriptions-signup>
          </div>
        </mat-step>

      </mat-stepper>

    </div>
  </div>
</div>
