<div class="container-fluid animated fadeIn">
    <h1>Payment Method</h1>

    <!-- Credit card information -->
    <h4 class="my-4 text-body-tertiary fw-bold">Credit Card Information</h4>
    <form action="" class="form" [formGroup]="creditCardForm">
        <div class="row row-gap-3">
            <div class="col-lg-4 col-md-12">
                <div class="form-floating has-validation">
                    <input formControlName="creditCardNumber" class="form-control" id="creditCardNumber"
                        [class.is-invalid]="validateErrors('creditCardNumber')" (input)="formatCreditCardNumber()"
                        placeholder>
                    <label for="creditCardNumber">Credit card number</label>
                    <div *ngIf="validateErrors('creditCardNumber')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('creditCardNumber')}}
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="form-floating has-validation">
                    <input formControlName="firstName" [class.is-invalid]="validateErrors('firstName')" type="text"
                        class="form-control" id="firstName" placeholder>
                    <label for="firstName">First name</label>
                    <div *ngIf="validateErrors('firstName')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('firstName')}}
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="form-floating has-validation">
                    <input formControlName="lastName" [class.is-invalid]="validateErrors('lastName')" type="text"
                        class="form-control" id="lastName" placeholder>
                    <label for="lastName">Last name</label>
                    <div *ngIf="validateErrors('lastName')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('lastName')}}
                    </div>
                </div>
                @if (!isEditionModeEnable()) {
                <button class="btn float-end btn-sm btn-outline-primary mt-2" (click)="enableEditionMode()">Edit credit
                    card</button>
                }
            </div>

            @if (isEditionModeEnable()) {
            <div class="col-lg-6 col-md-12">
                <div class="form-floating has-validation">
                    <input class="form-control" [class.is-invalid]="validateErrors('expirationDate')"
                        id="expirationDate" placeholder formControlName="expirationDate" maxlength="7" appMaskDate
                        required>
                    <label for="expirationDate">Expiration Date (MM/YYYY)</label>
                    <div *ngIf="validateErrors('expirationDate')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('expirationDate')}}
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="form-floating has-validation">
                    <input formControlName="securityCode" type="password"
                        [class.is-invalid]="validateErrors('securityCode')" appOnlyNumber
                        [maxlength]="creditCardSecurityCodeMaxLen()" class="form-control" id="securityCode" placeholder>
                    <label for="securityCode">Security code</label>
                    <div *ngIf="validateErrors('securityCode')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('securityCode')}}
                    </div>
                </div>
            </div>
            }

        </div>
    </form>

    <!-- Billing information -->
    @if (this.isEditionModeEnable()){
    <h4 class="my-4 text-body-tertiary fw-bold">Billing Information</h4>
    <div class="d-grid col-12">
        <mat-slide-toggle [(ngModel)]="toggleBillingForm"
            (change)="billingAddressToggleChangeHandler($event.checked)">My delivery address is different than my
            billing address</mat-slide-toggle>
    </div>
    @if(toggleBillingForm()) {
    <form action="" [formGroup]="billingForm">
        <div class="row mt-4 row-gap-3">
            <div class="col-lg-6 col-md-12">
                <div class="form-floating">
                    <input class="form-control" id="address" placeholder formControlName="street">
                    <label for="address">Address</label>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="form-floating">
                    <input formControlName="additional" type="text" class="form-control" id="additionalAddressCtrl"
                        placeholder="Type your Additional address informatios">
                    <label for="additionalAddressCtrl">Additional address information</label>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="form-floating">
                    <input formControlName="city" type="text" class="form-control" id="cityCtrl"
                        placeholder="Type your City">
                    <label for="cityCtrl">City</label>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <mat-form-field class="w-100">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state" name="stateCtrl2">
                        @for (item of states(); track $index) {
                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="form-floating">
                    <input formControlName="zipCode" type="text" class="form-control" id="zipCodeCtrl"
                        placeholder="Type your ZIP Code">
                    <label for="zipCodeCtrl">ZIP Code</label>
                </div>
            </div>
        </div>
    </form>
    }
    }

    <div [ngClass]="{'col-lg-6': isEditionModeEnable(), 'col-lg-3': !isEditionModeEnable()}"
        class="d-flex gap-4 col-md-12 mx-auto mt-5">
        @if (isEditionModeEnable()) {
        <div class="d-grid col">
            <button type="button" class="btn btn-outline-primary" (click)="discardChangesHandler()">Discard
                Changes</button>
        </div>
        }
        @if (isEditionModeEnable() || toggleBillingForm()) {
        <div class="d-grid col">
            <button type="button" class="btn btn-primary" (click)="handleSaveChangesClicked()">Save Changes</button>
        </div>
        }
    </div>
</div>