import { CommonModule } from '@angular/common';
import { Component, OnInit, computed, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentService } from '../../../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../../../shared/constants/modal-content-types';
import { ModalContentData } from '../../../shared/modal-content/modal-content';
import { Router } from '@angular/router';
import { OrderHistoryService } from './order-and-billing.service';
import { filter, tap } from 'rxjs';
import { BuyAgainProducts, BuyAgainResponse, OrderHistoryProductResponse } from './order-and-billing.types';
import { ApiResponse } from '../../../shared/common/types';
import { ResolutionService } from '../../../shared/resolution.service';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { EmptyMessageComponent } from '../../../shared/empty-message/empty-message.component';
import { OrderService } from '../../../shared/order.service';
import { CalculationsService } from '../../../shared/calculations.service';

@Component({
  selector: 'app-order-and-billing',
  standalone: true,
  providers: [provideNativeDateAdapter(), OrderHistoryService],
  imports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    EmptyMessageComponent
  ],
  templateUrl: './order-and-billing.component.html',
  styleUrl: './order-and-billing.component.scss'
})
export class OrderAndBillingComponent implements OnInit {

  private modalService = inject(ModalContentService);
  private router = inject(Router);
  private orderHistoryService = inject(OrderHistoryService);
  private resolutionsService = inject(ResolutionService);
  private signalsStoreService = inject(SignalsStoreService);
  #orderService = inject(OrderService);
  #calculationsService = inject(CalculationsService);

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  orderHistory = computed(() => this.orderHistoryService.orderHistory());
  credits = computed(() => this.#setUpCredits());

  isMobile = computed(() => this.resolutionsService.isMobile());

  isLimitedUser = computed(() => this.signalsStoreService.isLimitedUser());

  emptyMessage = 'You have no order history at this moment.';

  ngOnInit(): void {
    this.orderHistoryService.getOrderHistory();
  }

  openModalBuyAgain(id: number) {
    this.modalService.openModal(ModalContentTypes.BUY_AGAIN, {
      title: 'Buy again',
      textContent: this.isMobile() ? '' : 'You are about to reorder these products',
      buyAgain: {
        orderHistoryId: id
      },
      fullScreenOnMobile: true
    } as ModalContentData).closed.pipe(
      filter((value: OrderHistoryProductResponse[]) => !!value?.length),
      tap((value: OrderHistoryProductResponse[]) => this.buyAgain(value))
    ).subscribe();
  }

  goToOrderDetail(id: any) {
    this.router.navigate([`/settings/billing/order-and-billing/${id}`])
  }

  private buyAgain(products: OrderHistoryProductResponse[]) {
    const body: BuyAgainProducts[] = products.map(p => {
      return {
        id: p.id,
        variantId: p.variantId,
        quantity: p.quantity.requested,
        packageId: p.package?.id ?? null
      } as BuyAgainProducts
    });

    this.orderHistoryService.buyAgainProducts(body).pipe(
      tap((response: ApiResponse<BuyAgainResponse>) => {
        if (!response.data.hasAllProductsAdded) {
          this.openModalInfo(response.data, products);
        }
      })
    ).subscribe();
  }

  private openModalInfo(response: BuyAgainResponse, products: OrderHistoryProductResponse[]) {
    const outOfStock = products.filter(p => response.outOfStock.some(e => e.variantId === p.variantId)).map(p => {
      return {
        productName: p.name,
        productVariant: p.attributes[0].name ? `${p.attributes[0].name}: ${p.attributes[0].value.name}` : '---',
        outOfStock: true
      };
    });
    const inOrder = products.filter(p => response.alreadyInOrder.some(e => e.variantId === p.variantId)).map(p => {
      return {
        productName: p.name,
        productVariant: p.attributes[0].name ? `${p.attributes[0].name}: ${p.attributes[0].value.name}` : '---'
      };
    });
    const noAvailableProducts = products.filter(p => response.notAvailableProducts.some(e => e.variantId === p.variantId)).map(p => {
      return {
        productName: p.name,
        productVariant: p.attributes[0].name ? `${p.attributes[0].name}: ${p.attributes[0].value.name}` : '---',
        reason: 'Product not available'
      };
    });

    this.modalService.openModal(ModalContentTypes.BUY_AGAIN_WARNING, {
      title: 'Oops!',
      textContent: 'Sorry, these products are already in your order or they are out of stock',
      orderHistoryWarning: { products: [...outOfStock, ...inOrder, ...noAvailableProducts], showThirdColumn: true, fromOrder: false }
    })
  }

  #setUpCredits() {
    const odooOrder = this.#orderService.odooOrder()[0];
    const firebaseOrder = this.signalsStoreService.firebaseOrder();
    const products = this.#calculationsService.setUpOrderProducts(odooOrder, firebaseOrder)

    const creditsAmount = odooOrder?.paymentDetails?.credits || 0;
    const subTotal = this.#calculationsService.getOrderSubTotal(odooOrder, firebaseOrder);
    const deliveryFee = this.#calculationsService.getOrderDeliveryFee(odooOrder, subTotal, products);
    const taxes = this.#calculationsService.getOrderTaxes(products, odooOrder, deliveryFee);
    const tipAmount = this.#calculationsService.getTipAmount(odooOrder, firebaseOrder, products);
    const donationAmountVoluntary = this.#calculationsService.getDonationVoluntaryAmount(odooOrder, firebaseOrder, products);
    return this.#calculationsService.getOrderCreditsBalance({
      creditsAmount,
      subTotal,
      deliveryFee,
      taxes,
      tipAmount,
      donationAmountVoluntary
    })
  }
}
