@if (isContentLoading()) {
<mat-progress-bar class=" rounded layout-shop__progress-bar" mode="indeterminate" value="accent"></mat-progress-bar>
}
<div class="layout-shop" [class.layout--sidebar-closed]="!isSidebarOpen()">
  <!-- Sidebar -->
  @if(isContentLoaded()){
  <div class="layout-shop__sidebar" [class.sidebar--closed]="!isSidebarOpen()"
    [class.height-layout]="!hasGlobalMessages()" [class.height-layout-global-message]="hasGlobalMessages()">
    <div class="overlay" (click)="toggleSidebar()"></div>
    <app-sidebar></app-sidebar>
    <div class="toggle-sidebar" (click)="toggleSidebar()" (mouseenter)="toggleCollapseBarHover(true)"
      (mouseleave)="toggleCollapseBarHover(false)">

      <i [ngClass]="{
          'fas': true,
          'fw-bolder': true,
          'toggle-sidebar__icon': true,
          'fa-angles-right': !isSidebarOpen(),
          'fa-angles-left': isSidebarOpen(),
        }"></i>
    </div>
  </div>
  }

  <!-- Main content -->
  <div class="layout-shop__main" [class.height-layout-shop__main]="!hasGlobalMessages()"
    [class.height-layout-shop__main-global-message]="hasGlobalMessages()">

    @if(!marketStatus().isOpen) {
    <div class="closed-market-container animated fadeIn">
      <div class="alert" role="alert">
        <span class="material-symbols-outlined mat-icon-fill icon">
          calendar_today
        </span>
        <div class="text-container">

          <p class="header">{{closedMarket().title}}</p>
          <p class="legend">{{closedMarket().legend}}</p>
        </div>
      </div>
    </div>
    }

    <div class="container-fluid">

      @if (!isContentLoading() && isContentLoaded()) {
      @if (((hasSession() && (!stockService.mapStockSignal().size || !productsSignal().length))) || (!hasSession()
      && !productsSignal().length)) {
      <app-empty-message [message]="emptyMessage" class="mt-4"></app-empty-message>
      }
      }

      <div class="row product-card-grid" id="scroll-to-top-ref">
        @defer (on immediate; when productsSignal().length) {
        @if (productsSignal()) {

        @for (product of productsSignal(); track product.id) {

        <!-- PRODUCT CATEGORY -->
        @if (product.showCategory) {
        @if (getCategoryBanner(product.category.id); as categoryBanner) {
        <a [href]="categoryBanner.url ? categoryBanner.url : 'javascrip:void(0)'">

          <img [src]="categoryBanner.banner" alt="Banner of category: {{product.category.name}}"
            class="img-fluid animated fadeInDown w-100 mb-4">

        </a>
        } @else if(displayCategoryName()) {
        <h3 class="mb-2 mb-sm-4">
          {{ product.category.name }}
        </h3>
        }
        }
        <!-- PRODUCT CATEGORY ENDS -->

        <!-- PRODUCT SUBCATEGORY -->
        @if (product.showSubcategory) {
        @if (getSubCategoryBanner(product.category.id, product.subcategory.id); as subcategoryBanner) {
        <a [href]="subcategoryBanner.url ? subcategoryBanner.url : 'javascrip:void(0)'">

          <img [src]="subcategoryBanner.banner" alt="Banner of subcategory: {{product.subcategory.name}}"
            class="img-fluid animated fadeInDown w-100 mb-4">

        </a>
        } @else {
        <h3 class="mb-2 mb-sm-4">
          {{ product.subcategory.name }}
        </h3>
        }
        }
        <!-- PRODUCT SUBCATEGORY ENDS-->

        @defer (on immediate; prefetch on immediate) {
        <div class="col-xxl-3 col-lg-4 col-6 animated fadeIn" id="{{product.id}}">
          <div class="product-card-grid__item">
            <app-product-card [card]="{ product }">
            </app-product-card>
          </div>
        </div>
        } @placeholder {
        <div class="col-xxl-3 col-lg-4 col-6  animated fadeInUp fadeIn mb-3" id="{{product.id}}">
          <div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
            <span class="placeholder bg-secondary col-12"></span>
            <span class="placeholder bg-secondary col-6"></span>
            <span class="placeholder bg-secondary col-6"></span>
            <div class="placeholder bg-secondary col-12" style="height: 100px;"></div>
            <div class="card-body">
              <h5 class="card-title placeholder-glow">
                <span class="placeholder bg-secondary col-6"></span>
              </h5>
              <p class="card-text placeholder-glow">
                <span class="placeholder bg-secondary col-7"></span>
                <span class="placeholder bg-secondary col-4"></span>
                <span class="placeholder bg-secondary col-4"></span>
                <span class="placeholder bg-secondary col-6"></span>
                <span class="placeholder bg-secondary col-8"></span>
              </p>
              <a class="btn btn-secondary disabled placeholder col-12" aria-disabled="true"></a>
            </div>
          </div>
        </div>
        }
        }
        }

        }
        @placeholder { LOADING... }
      </div>
    </div>
  </div>
</div>
