import { Component, computed, inject, OnInit, signal, viewChild } from '@angular/core';
import { OrderService } from '../shared/order.service';
import { OrderComponent } from '../order/order.component';
import { SignalsStoreService } from '../shared/signals-store.service';
import { RouterModule } from '@angular/router';
import { OrderTab } from './orders.types';
import { formatDateToReadableString } from '../shared/utils/formatting';
import { DateTime } from 'luxon';
import { toObservable } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { StockService } from '../stock/stock.service';
import { Session } from '../shared/types/session.type';
import { LOCALSTORAGE_KEYS } from '../shared/constants/databases';
import { LocalStorageService } from '../shared/local-storage.service';
import { ResolutionService } from '../shared/resolution.service';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    OrderComponent,
    RouterModule
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})
export class OrdersComponent implements OnInit {

  //#region Services

  #orderService = inject(OrderService);

  #signalsStoreService = inject(SignalsStoreService);

  #stockService = inject(StockService);

  #localStorageService = inject(LocalStorageService);

  #resolutionService = inject(ResolutionService);

  //#endregion

  //#region Properties

  orderComponent = viewChild<OrderComponent>('orderComponent');

  isMobile = computed(() => this.#resolutionService.isMobile());

  tabs = computed(() => this.#loadTabsInfo());

  tabsInView = signal<OrderTab[]>([]);

  options = signal<OrderTab[]>([]);

  $finalizeTabsLoad = toObservable(this.tabs);

  isLoaded = computed(() => this.#orderService.hasGetOddooOrders() && this.#signalsStoreService.hasGetFirebaseOrders());

  currentOrder = signal<any>(null);

  isOrderSelected = signal(false);

  tabSelected = signal<OrderTab | null>(null);

  minOrder = signal(0);

  //#endregion

  //#region Constructor

  ngOnInit() {

    if (!this.tabs().length)
      this.#orderService.getOrder(true);

    this.$finalizeTabsLoad
      .pipe(
        tap(x => {

          if (!x.length)
            return;

          if (!this.tabSelected())
            this.#getOrderByDefault();

          this.#setCurrentTabSelected();
        })
      )
      .subscribe();
  }

  //#endregion

  //#region Methods

  #loadTabsInfo(): OrderTab[] {

    if (!this.isLoaded())
      return [];

    const tabs: OrderTab[] = [];

    for (const { deliveryInfo, id } of this.#orderService.odooOrder()) {

      if (!deliveryInfo) continue;

      let hasPendingChanges = false;

      const firebaseOrder = this.#signalsStoreService
        .firebaseOrder()
        .find(x => x.deliveryDate === deliveryInfo.deliveryDate);

      if (firebaseOrder && (firebaseOrder?.products?.common?.length || firebaseOrder?.products?.subscription?.length))
        hasPendingChanges = true;

      const { cutoffDate, cutoffTime } = deliveryInfo;

      tabs.push({
        id,
        deliveryDate: this.#customDate(deliveryInfo.deliveryDate),
        shortDeliveryDate: this.#customDate(deliveryInfo.deliveryDate, true),
        cutoff: `${this.#customDate(cutoffDate)} at ${cutoffTime}`,
        deliveryWindow: deliveryInfo.deliveryWindow,
        originalDeliveryDate: deliveryInfo.deliveryDate,
        selected: false,
        hasPendingChanges
      });
    }

    const firebaseOrders = this.#signalsStoreService
      .firebaseOrder()
      .filter(x => !tabs
        .map(y => y.originalDeliveryDate)
        .includes(x.deliveryDate)
      );

    for (const firebase of firebaseOrders) {

      let cutoffDate = '';

      if (firebase.cutoffDate) {

        const cutoff = DateTime
          .fromMillis(firebase.cutoffDate)
          .toUTC()
          .toFormat('yyyy-MM-dd_hh:mm a').split('_');

        cutoffDate = `${this.#customDate(cutoff[0])} at ${cutoff[1]}`;
      }

      tabs.push({
        deliveryDate: this.#customDate(firebase.deliveryDate),
        shortDeliveryDate: this.#customDate(firebase.deliveryDate, true),
        cutoff: cutoffDate,
        deliveryWindow: firebase.deliveryWindow ?? '',
        originalDeliveryDate: firebase.deliveryDate,
        selected: false,
        hasPendingChanges: true
      });
    }

    return tabs
      .sort((a, b) => +a.originalDeliveryDate.replaceAll('-', '') < +b.originalDeliveryDate.replaceAll('-', '') ? -1 : 1);
  }

  #customDate(date: string, isMonthShort = false) {
    const { dayName, month, shortMonth, day } = formatDateToReadableString(date);
    return `${dayName}, ${isMonthShort ? shortMonth : month} ${day}`;
  }

  #clearSelection() {
    this.tabs().forEach(x => x.selected = false);
    this.currentOrder.set(null);
    this.isOrderSelected.set(false);
  }

  #setCurrentTabSelected() {

    const orderSelected = this.#localStorageService
      .get(LOCALSTORAGE_KEYS.ORDER_SELECTED);

    const index = this.tabs()
      .findIndex(x => x.originalDeliveryDate === orderSelected);

    this.tabSelected.set(null);
    this.#selectOrder(this.tabs()[index === -1 ? 0 : index]);
  }

  #selectOrder(tab: OrderTab) {

    this.#clearSelection();
    this.tabSelected.set(tab);

    // Get the order from odoo.

    const odooOrder = this.#orderService
      .odooOrder()
      .find(x => x.deliveryInfo.deliveryDate === tab.originalDeliveryDate);

    // Set this property in false because is needed for show the order summary when another order was skipped.

    const orderComp = this.orderComponent()

    if (orderComp)
      orderComp.isConfirmedSkipWeekDelivery = false;

    this.currentOrder.set(odooOrder);

    this.isOrderSelected.set(true);
    tab.selected = true;

    this.#localStorageService
      .set(LOCALSTORAGE_KEYS.ORDER_SELECTED, tab.originalDeliveryDate);

    this.tabsInView
      .set(this.#loadTabsInView());

    this.options
      .set(this.#loadOptions());
  }

  #getOrderByDefault() {

    const defaultOdooOrder = this.#orderService
      .odooOrder()
      .find(x => x.deliveryInfo.deliveryDate === this.#getCurrentDeliveryDate());

    if (!defaultOdooOrder)
      return;

    this.minOrder.set(defaultOdooOrder.paymentDetails?.minOrder ?? 0);
  }

  #getCurrentDeliveryDate() {

    const currentSessionInfo: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION);

    if (!currentSessionInfo)
      return '';

    return currentSessionInfo.deliveryInfo?.deliveryDate ?? '';
  }

  prevSelectOrder(tab: OrderTab) {

    if (this.#stockService.isLoading())
      return;

    this.#selectOrder(tab);
  }

  #loadTabsInView() {

    if (this.isMobile())
      return this.tabs().filter(x => x.selected);
    else
      return this.tabs();
  }

  #loadOptions() {
    return this.tabs().filter(x => !x.selected);
  }

  //#endregion
}
