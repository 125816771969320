<div class="container-fluid p-5">

  @defer (on immediate; when !isLoadingContent) {
  @if (!isLoadingContent) {
  <!-- Breadcrumbs: -->
  @if (!isLimitedUser()) {
  <div class="row">
    <div class="col align-self-start justify-content-start">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          @if (!isCustomBoxSignupFlow()) {
          <li class="breadcrumb-item">
            <a>
              <span class="material-symbols-outlined" [routerLink]="'/shop'">home</span>
            </a>
          </li>
          <li class="breadcrumb-item" (click)="gotoCategory(product()?.category?.name)">
            {{ product()?.category?.name }}
          </li>
          } @else {
          <li class="breadcrumb-item" [routerLink]="'/signup'">
            Go Back
          </li>
          }
          <li class="breadcrumb-item active" aria-current="page">
            <span class="fw-bold">{{ product()?.name }}</span>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  }
  @if(isMobile()) {
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#your-box" type="button" role="tab">Your
        Box</button>
    </li>
    @if(addableItems().size){
    <li class="nav-item">
      <button class="nav-link" data-bs-toggle="tab" data-bs-target="#add-ons" type="button" role="tab">Add-ons</button>
    </li>
    }
    @if(premiumAddableItems().size) {
    <li class="nav-item">
      <button class="nav-link" data-bs-toggle="tab" data-bs-target="#premium-add-ons" type="button" role="tab">Premium
        Add-ons</button>
    </li>
    }
  </ul>
  }

  <div [ngClass]="isMobile()  ? 'tab-content' : 'row'">
    <div [ngClass]="isMobile()  ? 'tab-pane fade show active' : 'col-md-5 p-3'" id="your-box" role="tabpanel">
      <div class="d-grid row-gap-3">
        <div class="row">
          <div class="col-lg-8">
            <h4>
              {{ product()?.name }}
            </h4>
          </div>
          <div class="col-lg-4 d-lg-flex justify-content-end">

            <!-- Amount total for normal flow -->
            @if (!isLimitedUser() || product()?.bundle.isLocked) {
            <span>{{product().quantity}} x {{ subTotalAmount() | currency }}</span>
            } @else {
            <!-- Items quantity for HOP (Limited) users -->
            <span><b>{{ addedItemsQuantity() }}</b> items</span>
            }
          </div>
        </div>

        <!-- Delivery date, delivery address and frequency for HOP and Farmshare (Limited) users -->
        @if (isLimitedUser()) {
        <!-- Delivery date -->
        @defer (on viewport; when signalStoreService.sessionSignal()) {
        <div class="order-info__item animated fadeIn">
          <div class="order-info__item-icon">
            <span class="material-symbols-outlined">local_shipping</span>
          </div>
          <div class="order-info__item-value">
            <div>
              <h5 class="order-info__item-title">
                {{deliveryInfo()?.deliveryDateText}}
              </h5>
              <p class="order-info__item-text">
                Edit before {{deliveryInfo()?.cutoffDateText}}
              </p>
            </div>
          </div>
        </div>
        } @placeholder {
        <div class="placeholder-glow animated fadeIn">
          <div class="placeholder col-12 py-5 bg-secondary"></div>
        </div>
        }

        <!-- Delivery address -->
        @defer (on viewport; when signalStoreService.sessionSignal()) {
        <div class="order-info__item is-default animated fadeIn">
          <div class="order-info__item-icon">
            <span class="material-symbols-outlined">home</span>
          </div>
          <div class="order-info__item-value">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h5 class="order-info__item-title">
                  {{addressInfo()?.title}}
                </h5>
                <p class="order-info__item-text">
                  {{ addressInfo()?.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
        } @placeholder {
        <div class="placeholder-glow animated fadeIn">
          <div class="placeholder col-12 py-5 bg-secondary"></div>
        </div>
        }

        <!-- Frequency -->
        <div class="row">
          <div class="col-12">
            <mat-form-field class="d-flex" subscriptSizing="dynamic">
              <mat-label> Frequency</mat-label>
              <mat-select [disabled]="!permissions()?.settings?.subscriptions?.allowed"
                [value]="selectedFrequency()?.value?.id || selectedFrequency()?.id || ''"
                (selectionChange)="onChangeFrequency( $event)">

                @for (freq of signalStoreService.frequencies(); track $index) {
                <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        } @else {
        <!-- Frequency and start date for normal flow -->
        @if (bundleEditionType() === bundleEditionTypes.subscription) {
        <div class="row animated fadeIn">
          <div class="col-6">
            @if (lockFrequencyButton()) {
            <mat-form-field class="d-flex" subscriptSizing="dynamic">
              <mat-label>Frequency</mat-label>
              <input matInput placeholder="Frequency" [readonly]="true"
                [value]="selectedFrequency()?.value?.name ?? ''">
            </mat-form-field>
            } @else {
            <mat-form-field class="d-flex" subscriptSizing="dynamic">
              <mat-label>Frequency</mat-label>
              <mat-select [value]="selectedFrequency()?.value?.id || selectedFrequency()?.id || ''"
                (selectionChange)="onChangeFrequency( $event)">
                @for (freq of signalStoreService.frequencies(); track $index) {
                <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            }
          </div>
          <div class="col-6">
            <mat-form-field class="d-flex" subscriptSizing="dynamic">
              <mat-label>
                Start date
              </mat-label>
              <input matInput placeholder="Start date" [readonly]="true" [value]="selectedStartDate() || ''" />
            </mat-form-field>
          </div>
        </div>
        }
        }

        <!-- Save changes/ Submit order -->
        <div class="row">
          <div class="col d-grid gap-2">
            <button class="btn btn-secondary btn-block" [disabled]="shouldDisableSaveButton()"
              (click)="saveAndSubscribe()">
              @if (!isLimitedUser()) {
              @if (bundleEditionType() === bundleEditionTypes.subscription) {
              Save customization
              @if (!product()?.existsInOrder) {
              & subscribe
              }
              } @else {
              @if(product()?.existsInOrder) {
              Save customization
              } @else {
              Add to cart
              }
              }
              } @else {
              Update/Submit Order
              }
            </button>
          </div>
        </div>

        <!-- SKIPPED ORDER FOR LIMITED USERS -->
        @if (isLimitedUser() && isOrderSkiped()) {
        <div class="alert alert-warning d-flex align-items-center animated fadeIn m-0" role="alert">
          <i class="fa fa-info-circle text-primary me-2"></i>
          <div class="h7-regular">
            You’ve skipped this order. If you’d like to change your mind, just click the button below to remove the
            skip.
          </div>
        </div>
        <div class="row animated fadeIn">
          <div class="col d-grid gap-2">
            <button class="btn btn-secondary btn-block" (click)="unskipOrder()">
              Remove Skip
            </button>
          </div>
        </div>
        }

        <!-- Minimum box items -->
        @if (showLimitedMinimumOrder()) {
        <div class="order-info__item is-not-flex animated fadeIn">
          <p class="m-0">Your box minimum order ({{boxMinimumOrder()}})</p>
          <section class="d-flex align-items-center gap-2 justify-content-around order-scale">
            <mat-progress-bar mode="determinate" [value]="boxPercentOfMinLimit()"></mat-progress-bar>
            <small class="text-nowrap">{{ addedItemsQuantity() }} items</small>
          </section>
        </div>
        }

        <!-- Maximum box items -->
        @if (showLimitedMaximumOrder() && !showLimitedMinimumOrder()) {
        <div class="order-info__item is-not-flex animated fadeIn">
          <p class="m-0">Your box maximum order ({{boxMaximumOrder()}})</p>
          <section class="d-flex align-items-center gap-2 justify-content-around order-scale">
            <mat-progress-bar mode="determinate" [value]="boxPercentOfLimit()"></mat-progress-bar>
            <small class="text-nowrap">{{ addedItemsQuantity() }} items</small>
          </section>
        </div>
        }

        <!-- Disregard option -->
        @if (!isLimitedUser() && isAbleToDiscardChanges()) {
        <div class="row">
          <div class="col d-flex justify-content-end">
            <a href="javascript:void(0)" (click)="undoChanges()">
              <small>
                Discard Changes
              </small>
            </a>
          </div>
        </div>
        }

        <!-- Added items -->
        <div class="row added-section">
          @if (items().size) {
          @for (bundleItem of items().values(); track $index) {
          <div class="col-sm-12 col-md-6 col-lg-6 animated fadeIn custom-box">
            <app-addable-product-card [removable]="true" [product]="bundleItem" [isLimitedUser]="isLimitedUser()"
              [isLockedBundle]="product().bundle.isLocked || false" (removeItem)="onRemoveItem($event)"
              [isFixedBundle]="product().bundle.isFixed" (changeQuantity)="onChangeQuantity($event)">
            </app-addable-product-card>
          </div>
          }
          }
        </div>
      </div>
    </div>

    @if (addableItems().size) {
    <div [ngClass]="isMobile()  ? 'tab-pane fade' : 'col-md-7'" id="add-ons" role="tabpanel">
      <div class="d-none d-sm-block px-md-3 px-0">
        <h3 class="h3-semibold">
          Add-ons
        </h3>
      </div>
      <mat-tab-group>
        @for (categoryName of addableItems().keys(); track categoryName) {
        @if (addableItems().has(categoryName) && addableItems().get(categoryName)?.size) {
        <mat-tab [label]="removeCharByRegex(categoryName, '_')">
          <div class="row-addon">
            @for (addableProduct of addableItems().get(categoryName).values(); track addableProduct?.id) {
            <div class="col-addon">
              <app-addable-product-card [product]="addableProduct" [isLimitedUser]="isLimitedUser()"
                [isFixedBundle]="product().bundle.isFixed" [isLockedBundle]="product().bundle.isLocked || false"
                (addItem)="onAddItem($event)">
              </app-addable-product-card>
            </div>
            }
          </div>
        </mat-tab>
        }
        }
      </mat-tab-group>
    </div>
    }
    <!-- PREMIUM ADD ONS -->
    @if (premiumAddableItems().size) {
    <div [ngClass]="isMobile()  ? 'tab-pane fade' : 'col-md-7'" id="premium-add-ons" role="tabpanel">
      <div class="d-none d-sm-block px-md-3 px-0">
        <h3 class="h3-semibold">
          Premium Add-ons
        </h3>
      </div>
      <mat-tab-group>
        @for (categoryName of premiumAddableItems().keys(); track categoryName) {
        @if (premiumAddableItems().has(categoryName) && premiumAddableItems().get(categoryName)?.size) {
        <mat-tab [label]="removeCharByRegex(categoryName, '_')">
          <div class="row-addon">
            @for (addableProduct of premiumAddableItems().get(categoryName).values(); track addableProduct?.id) {
            <div class="col-addon">
              <app-addable-product-card [product]="addableProduct" [isLimitedUser]="isLimitedUser()"
                [isFixedBundle]="product().bundle.isFixed" [isLockedBundle]="product().bundle.isLocked || false"
                (addItem)="onAddItem($event)">
              </app-addable-product-card>
            </div>
            }
          </div>
        </mat-tab>
        }
        }
      </mat-tab-group>
    </div>
    }

  </div>
  }
  } @placeholder {
  LOADING...
  }
</div>