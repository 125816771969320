import { Component, inject, OnInit } from '@angular/core';
import { FADEIN_OUT_ANIMATION } from '../../../shared/constants/animations';
import { NgForOf } from "@angular/common";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { environment } from '../../../../environments/environment';

@Component({
  // animations: FADEIN_OUT_ANIMATION,
  selector: 'app-members',
  standalone: true,
  imports: [
    NgForOf,
    NgbAccordionModule
  ],
  templateUrl: './members.component.html',
  styleUrl: './members.component.scss'
})
export class MembersComponent implements OnInit {

  private meta = inject(Meta);
  private title = inject(Title);

  faq = [
    {
      count: 1,
      question: 'How do I shop with Mother Earth? Can I shop in person?',
      answer: 'We are exclusively an online retailer and as such, we do not have a physical storefront. Our shop can be found online (on this same website!). We require pre-orders for both delivery and pick up orders. When you sign up, you will receive detailed information about our delivery and pick up processes via email.'
    },
    {
      count: 2,
      question: 'How do I create an account with Mother Earth?',
      answer: 'Creating an account with Mother Earth is easy! To begin, click “<a href=\'/signup\'>Sign Up</a>” on our website. From there, you can select: home delivery to check if your address is in our delivery area; pick up at our West Asheville warehouse; or EBT pick up, also at our West Asheville warehouse. In a few simple steps, you can set up your basic account information, tell us about your dietary preferences and household size, and add your payment method. At this sign-up stage, you can also sign up for and customize your first box of local and delicious fruits and veggies! '
    },
    {
      count: 3,
      question: 'When can I place my order? Which days can I receive delivery (or pick up)?',
      answer: 'You can place orders on a rolling basis. Orders can be delivered to addresses in our delivery area, or picked up, <em>Tuesday through Saturday</em> - your choice! <br/> If your address is not in our delivery area, or if you don’t wish to pay our delivery fee, you can pick up your order from our West <strong>Asheville warehouse</strong> location. <br/>Orders for Tuesday delivery are due Sunday at <strong>11:30 PM</strong> <br/>orders for Wednesday delivery are due Monday at <strong>11:30 PM</strong> <br/>orders for Thursday delivery are due Tuesday at <strong>11:30 PM</strong> <br/>orders for Friday delivery are due Wednesday at <strong>11:30 PM</strong> <br/>and orders for Saturday delivery are due Thursday at <strong>11:30 PM</strong>.'
    },
    {
      count: 4,
      question: 'Is there an order minimum? How do I get free delivery?',
      answer: 'Orders must be at least a $20 subtotal for us to deliver to you. If your order does not meet this minimum, it will be automatically deleted by our system. Our delivery fee is $8.99 unless your subtotal is $75 or more: then it’s free!'
    },
    {
      count: 5,
      question: 'I was an existing Mother Earth customer on your old website. Do I have to do anything with my account on the new website?',
      answer: 'We worked very hard to make this transition as seamless as possible for all of our existing customers! The only thing you may have to do is reset your password; when you go to log in, follow the forgot password prompts. Upon logging in, you will find all of your subscription preferences and will be able to shop as usual. If you have any questions about the new website, don’t hesitate to contact Customer Care at <a href=\'mailto:terra@motherearthfood.com\'>terra@motherearthfood.com</a>.'
    },
    {
      count: 6,
      question: 'I forgot my password. How can I reset it?',
      answer: 'As you attempt to log in, just follow the “forgot password” link and an email will be sent with instructions to reset. If you have any trouble, please contact Customer Care at <a href=\'mailto:terra@motherearthfood.com\'>terra@motherearthfood.com</a>.'
    },
    {
      count: 7,
      question: 'Where do we deliver?',
      answer: 'We serve Asheville, NC and roughly 10 miles surrounding Asheville. You can check to see if we deliver to you by searching your address on <a href=\'https://www.google.com/maps/d/u/0/viewer?mid=1ALvlXmCE1niYITtV6ShE1aVdAPU6HDU&ll=35.52382811093278%2C-82.6035505&z=9\' target=\'_blank\'>this map</a>. If you are outside of our delivery area, we offer pick up at our West Asheville warehouse. If you have any questions about whether or not we can deliver to you, please contact Customer Care at <a href=\'mailto:terra@motherearthfood.com\'>terra@motherearthfood.com</a>.'
    },
    {
      count: 8,
      question: 'Is this a subscription service only? Or can I order à la carte?',
      answer: 'Both! We are happy to provide our customers with a wide range of product selections and delivery/pick-up options. You can easily subscribe to products at varying delivery frequencies, or simply choose to order as you wish and à la carte. Mother Earth Food is your one-stop shop for the best local food in Asheville!'
    },
    {
      count: 9,
      question: 'If I have an active subscription, how do I skip a week?',
      answer: 'You can easily skip your weekly subscription by choosing “skip a week” on the delivery order confirmation page. If you would like to skip multiple weeks or place a hold, please go to your account profile preferences and choose “Manage Subscriptions.” Select your hold dates and hit save. Please note that all changes to orders must be made prior to the close of the order window.'
    },
    {
      count: 10,
      question: 'Why do you need my credit card at sign up?',
      answer: 'A card is required to finalize your account because orders can be placed immediately and on a rolling basis. Once you choose your desired delivery or pick up day, you can edit your order for the week until two days prior to your fulfillment date. This gives you the time and space to perfectly customize your order to your unique grocery needs every week! '
    },
    {
      count: 11,
      question: 'Can I change my delivery day?',
      answer: 'Yes, you absolutely can! If you want to manage your deliveries, you can find the “Manage Deliveries” option directly in your profile or via the quick link on your delivery confirmation page. By clicking on either of these options, you can change your delivery or pick-up preferences weekly or just for your next delivery. This gives you the ultimate flexibility to manage your Mother Earth order around your schedule.'
    },
    {
      count: 12,
      question: 'Can I switch my account from a home delivery account to a pick-up account, or vice versa?',
      answer: 'You sure can! If you visit your profile under the account settings menu option, you will see the option to toggle back and forth between being a home delivery or pick-up customer. As long as you make these changes before your cut-off day and time, it will take effect immediately for your active order!'
    },
    {
      count: 13,
      question: 'Can I tip my driver?',
      answer: 'Our drivers work long hours to bring your orders safely to your door! Tips for our drivers are appreciated, especially if you find their service to be exceptional. You may add a tip during the checkout process. Simply click on the “tip” after you’ve added your items to your cart. A window will pop up so you can enter the amount you wish to tip to your driver. You can tip one-time or set a recurring tip.'
    },
    {
      count: 14,
      question: 'How does billing work?',
      answer: 'When creating your account, we request your credit card information so that you can immediately begin interacting with our dynamic and live grocery shopping experience. We bill orders the afternoon before delivery. If your card is declined for any reason, you will automatically receive an email with important next steps.'
    },
    {
      count: 15,
      question: 'Where do you source your products?',
      answer: 'We depend on our network of farmers to supply us with seasonal, diverse produce. We develop personal relationships with every one of our growers and food artisans, most of which are small family-owned businesses like ourselves. We strive to source as close to home as possible, however not all food products (bananas, for example) can be grown in our local region. Certain seasonal products are sourced regionally or beyond. Each listing contains product information, as well as information about the producer and growing/raising practices.'
    },
    {
      count: 16,
      question: 'Can I pay with EBT?',
      answer: 'Yes, you can use your EBT card for purchasing products that fall within the parameters of SNAP. The majority of products offered by Mother Earth Food will fall into this category. When signing up, for the very first question, choose “SNAP/EBT.” After signing up, you’ll place orders as normal. Payment with your EBT card must be made in person, at our West Asheville location with the EBT card reader. Pick up orders can be made on Tuesdays-Thursdays from 10AM-3PM, Fridays from 11am - 6pm and Saturdays from 9am - 12pm. We cannot (yet) offer delivery with EBT, but it is something we are actively exploring for the future!'
    },
    {
      count: 17,
      question: 'What do I do if there’s an issue with my order?',
      answer: 'We want to make sure you are completely satisfied with your order. If you experience anything less, we will “make it right.” If an item is not up to quality standards, missing from your order, or otherwise, please use the linked form to <a href=\'https://forms.gle/YJx28uSQSEx6Ypgv6\' target=\'_blank\'>contact us</a>.'
    },
    {
      count: 18,
      question: 'What happens if something is out of stock?',
      answer: 'Product offering availability can fluctuate for a variety of reasons. Because we work primarily with small farms to source our weekly orders, we often are met with changing variables over which we have little control (quality, weather, supply chain issues, truck breakdowns, etc.). When these kinds of situations occur, we’ll try our best to substitute a comparable product. We will always notify you in advance of any product removals or substitutions, and will update your order total accordingly.'
    },
    {
      count: 19,
      question: 'What are Surplus Boxes and how do I get one?',
      answer: 'We sell Surplus Produce Boxes every Friday from 12pm - 6pm and Saturday 9am - 12pm. These boxes do not require a pre-order. We only build a limited number of these boxes with excess produce from the week prior, and they are sold first come, first served, so get here early! The boxes are $25 for 8 portions of seasonal produce. We are located at 29 Hawk Hill Rd. Enter the Smith Mill Works complex off Cedar Hill Rd., and continue straight until you reach a light green warehouse (you can’t miss us!). Pull up near the bay doors and ring the doorbell. We look forward to seeing you!'
    },
    {
      count: 20,
      question: 'How do I cancel my account?',
      answer: 'You can cancel your own account in your customer profile. If you have an upcoming order and it is past the cut-off time, you will still be responsible for that order. Please also note that if you have an outstanding balance in your account, you will not be able to close your account until the balance is paid.\n'
    },
  ];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.setUpSEOTags();
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/faq'
    const component = 'FAQ'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }
}
