<!-- Sidebar -->
<div class="sidebar-content" [class.height-sidebar]="!hasGlobalMessages()"
[class.height-sidebar-global-message]="hasGlobalMessages()">
<h5 class="sidebar__title animated fadeInLeft">{{ signalsStore.selectedCategory().name || '' }}</h5>
<div class="sidebar__body animated fadeInLeft">
    <div
      class="accordion"
      id="accordionSidebar">
      @for (item of sidebarService.sidebar(); track itemIndex; let itemIndex = $index) {
        <div class="accordion-item border-0">
          <h2 class="accordion-header animated fadeInLeft">
            <button
              class="accordion-button text-capitalize"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#' + item.id"
              aria-expanded="true"
              [attr.aria-controls]="'#' + item.id">
              {{ item.name }}
            </button>
          </h2>
          <div
            [id]="item.id"
            class="accordion-collapse collapse show">
            <div class="accordion-body">
              <ul>
                @for (sublist of item.sublist; track $index) {
                  <li>
                    <mat-checkbox
                      [(ngModel)]="sublist.checked"
                      class="animated fadeInLeft"
                      [disabled]="!!sublist.disabled"
                      (ngModelChange)="handleCheckChange(item, itemIndex)">
                      {{ sublist.name }}
                    </mat-checkbox>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
