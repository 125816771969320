@switch (type()) {
<!-- Used in product detail page: -->
@case ('withThumbnail') {
<div [class.invisible]="!shouldRenderCarousel()">
  <div class="keen-slider main_slider" #sliderMainProductRef (mouseenter)="showTextWhatsInsideButton.set(true)"
    (mouseleave)="showTextWhatsInsideButton.set(false)">
    @for (item of items(); track item) {
    <div class="keen-slider__slide number-slide"><img (error)="handleImageError($event)" [src]="item" /></div>
    }
    @if (showWhatsInsideButton()) {
    <div class="info-badge whats-inside">
      <button type="button" class="rolling-info" [class.active]="showTextWhatsInsideButton()"
        (click)="whatsInside($event)">
        <i class="rolling-info-icon fas fa-info-circle"></i>
        <span class="rolling-info__text">
          What's inside
        </span>
      </button>
    </div>
    }
  </div>
  <div class="keen-slider thumbnail_slider" #thumbnailRef>
    @for (item of items(); track item) {
    <div class="keen-slider__slide number-slide"><img (error)="handleImageError($event)" [src]="item" /></div>
    }
  </div>
</div>
}
<!-- Producer products carousel in the producers view: -->
@case ('producerProduct') {
<div class="navigation-wrapper">
  <div #sliderRef class="keen-slider">
    @if (items().length) {
    @for (item of items(); track item.content.product.id) {
    <div class="keen-slider__slide">
      <app-product-card [card]="item?.content"></app-product-card>
    </div>
    } @if (hasMoreItems()) {
    <div class="keen-slider__slide is-view-more" [routerLink]="['/shop/bakery']"
      (click)="this.producersService.setName()">
      <div class="product-card">
        <h4 class="h6-semibold">View more</h4>
      </div>
    </div>
    }
    }
  </div>
  <svg class="arrow arrow--left" (click)="slider.prev()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
  </svg>
  <svg class="arrow arrow--right" (click)="slider.next()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
  </svg>
</div>
}
<!-- Default carousel. Used for suggested product in the order -->
@default () {
<div class="navigation-wrapper">
  <div #sliderRef class="keen-slider">
    @if (items().length) {
    @for (item of items(); track item.content.product.variantId) {
    <div class="keen-slider__slide">
      @switch (item?.contentType) {
      @case ('image') {
      <img (error)="handleImageError($event)" [src]="item.content" alt="carousel-image" />
      } @default () {
      <app-product-card [card]="item?.content" [isFromBlog]="!!item.isFromBlog" #carouselProductCard>
      </app-product-card>
      }
      }
    </div>
    }
    }
  </div>

  <svg class="arrow arrow--left" (click)="slider.prev()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
  </svg>
  <svg class="arrow arrow--right" (click)="slider.next()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
  </svg>
</div>
}
}
