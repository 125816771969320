<!-- Main -->
<section class="blog-feature animated fadeIn" [style.background-image]="backgroundImage()">
  @if (blog()) {
  <div class="blog-feature__content animated fadeInDown">
    <div class="blog-category">
      <span class="category" [ngStyle]="{'background-color': blog()?.category?.color}">{{blog()?.category?.name}}</span>
    </div>
    <h1 class="h1-semibold head-font">{{blog()?.title}}</h1>
    <button type="button" class="btn btn-static btn-primary" (click)="goToDetail(blog())">
      Read
    </button>
  </div>
  }
</section>

<section class="container animated fadeIn px-4">

  <!-- Categories -->
  @defer (when !isLoading()) {
  @if (isMobile()) {
  <div class="row mt-4 mb-2">
    <div class="col-xs-12 col-sm-6 col-md-4">
      <mat-form-field class="d-flex" subscriptSizing="dynamic">
        <mat-label>Category</mat-label>
        <mat-select [value]="categoryId()">
          @for (category of categories(); track category.id) {
          <mat-option [value]="category.id" (click)="changeCategory(category)">
            {{category.name}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  } @else {
  <ul class="category-list">
    @for (category of categories(); track category.id) {
    <li (click)="changeCategory(category)" class="animated fadeInDown">
      <span class="category-list__item" [class.active]="category.isSelected">
        {{category.name}}
      </span>
    </li>
    }
  </ul>
  }
  } @placeholder (minimum 500ms) {
  <ul class="category-list animated fadeInDown placeholder-glow">
    <li class="placeholder bg-secondary category-list__skeleton">
    </li>
    <li class="placeholder bg-secondary category-list__skeleton">
    </li>
    <li class="placeholder bg-secondary category-list__skeleton">
    </li>
  </ul>
  }

  <!-- Blog Posts -->
  <section class="row">
    @for (blog of blogs(); track blog.id) {
    <div class="col-sm-4 mt-2 animated fadeIn">
      <app-post [blog]="blog"></app-post>
    </div>
    } @empty {
    <h3 class="h3-regular text-center animated fadeIn mb-5">
      Oops! It looks like there are no blog posts here right now. Check back soon for updates!
    </h3>
    }
  </section>

  <!-- Pagination -->
  @if (blogs().length) {
  <div class="d-flex justify-content-center">
    <mat-paginator [length]="total()" [pageSize]="size()" [pageIndex]="page()" [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="handlePageEvent($event)" [showFirstLastButtons]="true" aria-label="Select page">
    </mat-paginator>
  </div>
  }

</section>