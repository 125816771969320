import { Component, computed, inject, input, InputSignal, output, signal } from '@angular/core';
import { PreOrderedProduct } from '../../../../product/product.types';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { depositConfig } from '../../../../product/product.contstants';
import { ResolutionService } from '../../../../shared/resolution.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NumberRestrictionDirective } from '../../../../shared/directives/number-restriction.directive';
import { MatInputModule } from '@angular/material/input';
import { OrderService } from '../../../../shared/order.service';
import { tap } from 'rxjs';
import { unableOperationMessage } from '../../../../shared/common/utils';
import { ModalContentService } from '../../../../shared/modal-content/modal-content.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentTypes } from '../../../../shared/constants/modal-content-types';

@Component({
  selector: 'app-pre-ordered-product',
  standalone: true,
  imports: [RouterLink, CommonModule, MatFormFieldModule, NumberRestrictionDirective, MatInputModule, NgbModule],
  templateUrl: './pre-ordered-product.component.html',
  styleUrl: './pre-ordered-product.component.scss'
})
export class PreOrderedProductComponent {

  #resolutionService = inject(ResolutionService);
  #orderService = inject(OrderService);
  #modalService = inject(ModalContentService);

  ou_lineUpdated = output();

  newQuantity = signal(0);
  prevQuantity = signal(0);
  hasPendingChanges = computed(() => this.newQuantity() !== this.prevQuantity());
  //#region "Minus" button setup:
  shouldDisableMinusButton = computed(() => !this.hasPendingChanges() || this.newQuantity() === 1 || !this.product()?.preOrder?.canUpdateProduct);
  minusButtonToolTipText = computed(() => this.shouldDisableMinusButton() ? 'You cannot reduce the quantity' : 'Remove Quantity');
  plusButtonToolTipText = computed(() => !this.product()?.preOrder?.canUpdateProduct ? 'You cannot increase the quantity' : 'Add Quantity');
  deadlinePassedMessage = signal(depositConfig.deadlinePassedMessage);


  product: InputSignal<PreOrderedProduct | undefined> = input(undefined, {
    transform: (data: PreOrderedProduct | undefined) => {
      if (!data) return data;
      const name = data.name;
      const variantName = data.variant?.attribute?.value?.name ?? '';
      data.name = variantName ? `${name} | ${variantName}` : name
      this.newQuantity.set(data.quantity);
      this.prevQuantity.set(data.quantity);
      return data;
    }
  });

  isMobile = computed(() => this.#resolutionService.isMobile());

  depositConfig = computed(() => {
    const preorder = this.product()?.preOrder;
    if (!preorder) return null;
    return depositConfig[preorder.depositType]
  })

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  handleMinus() {
    this.newQuantity.update(prev => {
      return prev === 1 ? 1 : prev - 1;
    })

  }

  handlePlus() {
    this.newQuantity.update(prev => {
      return prev === 99 ? 99 : prev + 1;
    })
  }

  updateQuantity() {
    const product = this.product();
    if (!product?.lineId) return unableOperationMessage(this.#modalService);

    this.#modalService.openModal(ModalContentTypes.CONFIRMATION, {
      title: depositConfig.increaseQuantityModal.title,
      textContent: depositConfig.increaseQuantityModal.message({ name: product.name, prevQuantity: this.prevQuantity(), newQuantity: this.newQuantity() }),
      cancelButtonText: 'No, go back',
      confirmButtonText: 'Yes, proceed'
    }).closed.pipe(
      tap((result: { confirm: boolean }) => {
        if (result.confirm) {
          this.#orderService.updatePreOrderLineQuantity({ lineId: product.lineId, quantity: this.newQuantity() })
            .pipe(tap(() => this.ou_lineUpdated.emit()))
            .subscribe();
        }
      })
    ).subscribe();
  }

  discardChanges() {
    const prev = this.prevQuantity();
    this.newQuantity.set(prev);
  }
}
