<div class="login vh-100 d-flex align-items-center">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-4 col">
        <img src="/assets/images/logo-main.svg?v={{logoVersionNumber()}}" alt="Mother Earth - Logo" class="login__logo">

        @if (showTempPasswordResetFlow()) {
        <p class="mt-4 text-lg-start text-md-start text-center">
          Welcome to our <b>NEW</b> e-Commerce experience! To gain access to your existing Mother Earth account
          click the button below to reset your password and log in to your Mother Earth account.
        </p>

        <div class="d-grid col-12 mb-4">
          <button class="btn btn-secondary w-100" [routerLink]="['/account-recovery/check-email']">
            Access my account now!
          </button>
        </div>
        }

      </div>
      <div class="col-lg-6 col-md-8 d-flex flex-column justify-content-center align-items-center">
        @if (!isMobile() || !showTempPasswordResetFlow()) {
        <h2 class="title-text">Welcome!</h2>
        }
        <form class="col-lg-8 d-flex flex-column mt-3 login-form" (ngSubmit)="onSubmit($event)">
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Email address</mat-label>
            <input type="email" #emailCtrl="ngModel" [ngModel]="email()" (ngModelChange)="onEmailChange($event)"
              matInput name="emailInput" required />
          </mat-form-field>
          <mat-error>
            @if ((emailCtrl.hasError('required') || emailCtrl.hasError('email')) && (emailCtrl.dirty ||
            emailCtrl.touched)) {
            {{ emailCtrl.hasError('required') ? 'Email is required' : 'Invalid email format' }}
            }
          </mat-error>

          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Password</mat-label>
            <input type="password" #passwordCtrl="ngModel" [ngModel]="password()"
              (ngModelChange)="onPasswordChange($event)" matInput name="passwordInput" required
              type="{{ hidePassword ? 'password' : 'text' }}" />
            <button mat-button matSuffix type="button" (click)="hidePassword = !hidePassword" class="eye-icon-button"
              [attr.aria-label]="'Toggle ' + (hidePassword ? 'visibility' : 'hide')">
              @if (hidePassword) {
              <span class="material-symbols-outlined text-muted">
                visibility
              </span>
              } @else {
              <span class="material-symbols-outlined text-muted">
                visibility_off
              </span>
              }
            </button>
          </mat-form-field>
          <mat-error>
            <small class="form-text">
              <p>Your password must have:</p>
              <div class="password-hint">
                @for (condition of passwordConditions; track $index) {
                <div class="hint-list">
                  <span class="hint-icon" [class]="condition.key() ? 'text-success' : 'text-danger'">
                    <i class="fas" [class.fa-circle-check]="condition.key()"
                      [class.fa-circle-xmark]="!condition.key()"></i>
                  </span>
                  {{ condition.text }}
                </div>
                }
              </div>
            </small>
          </mat-error>

          <div class="row mb-4 align-items-center">
            <div class="col">
              <mat-checkbox [(ngModel)]="hasExtendedSession" name="hasExtendedSession" class="remember-label">Remember
                me!</mat-checkbox>
            </div>
            <div class="col d-flex justify-content-end">
              <a href="#!" class="forget-link" [routerLink]="['/account-recovery/check-email']">Forgot password?</a>
            </div>
          </div>

          <button type="submit" class="btn btn-primary"
            [disabled]="!isPasswordValid() || !isEmailValid() || authService.isLoadingLogin()">
            @if (authService.isLoadingLogin()) {
            <i class="fas fa-spinner fa-spin"></i>
            }
            LOG IN
          </button>
        </form>

      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="login__footer-text">
          Don't have an account yet? <a href="javascript:void(0)" (click)="goToSignUp()">Sign up now</a>
        </div>
      </div>
    </div>

  </div>
</div>
