import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, ViewChild, WritableSignal, inject, input, output, signal, viewChildren } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import KeenSlider, { KeenSliderInstance, KeenSliderPlugin } from 'keen-slider';
import { ProductCardComponent } from '../product-card/product-card.component';
import { RouterModule } from '@angular/router';
import { ProducersService } from '../../pages/producers/producers.service';

function ThumbnailPlugin(main: KeenSliderInstance): KeenSliderPlugin {
  return (slider: any) => {
    function removeActive() {
      slider.slides.forEach((slide: any) => {
        slide.classList.remove('active');
      });
    }
    function addActive(idx: number) {
      slider?.slides[idx]?.classList?.add('active');
    }

    function addClickEvents() {
      slider.slides.forEach((slide: any, idx: any) => {
        slide.addEventListener('click', () => {
          main.moveToIdx(idx);
        });
      });
    }

    slider.on('created', () => {
      addActive(slider?.track?.details?.rel);
      addClickEvents();
      main.on('animationStarted', (main: any) => {
        removeActive();
        const next = main?.animator?.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider?.track?.details?.maxIdx, next));
      });
    });
  };
}
@Component({
  selector: 'app-carousel',
  standalone: true,
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  imports: [
    ProductCardComponent,
    RouterModule,
  ],
})
export class CarouselComponent implements AfterViewInit {

  //#region Properties

  @ViewChild('sliderRef')
  sliderRef!: ElementRef;

  @ViewChild('sliderMainProductRef')
  sliderMainProductRef!: ElementRef<HTMLElement>;

  @ViewChild('thumbnailRef')
  thumbnailRef!: ElementRef<HTMLElement>;

  itemsShowing: WritableSignal<any[]> = signal([]);
  items = input([], {
    alias: 'items',
    transform: (value: any | null) => {
      if (this.slider) {
        this.slider.destroy();
        setTimeout(() => {
          this.initSliders();
        }, 1);
      }
      // this.itemsShowing.set(value);
      return value;
    }
  });

  hasMoreItems = input<boolean>(false, { alias: 'hasMoreItems' });
  type = input<string>('', { alias: 'type' });

  slider: KeenSliderInstance | any = null;
  thumbnailSlider: KeenSliderInstance | any = null;

  producersService = inject(ProducersService);
  shouldRenderCarousel = signal(false);
  showTextWhatsInsideButton = signal(false);
  showWhatsInsideButton = input(false);
  onWhatsInsideClick = output<Event>();

  productsComponent = viewChildren<ProductCardComponent>('carouselProductCard');

  //#endregion

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }

  //#region Methods

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.initSliders();
  }

  private initSliders() {
    if (this.type() === 'withThumbnail')
      this.#setUpWithThumbnailSlider();
    else if (this.type() === 'producerProduct')
      this.#setUpProducerProductsSlider();
    else
      this.#setUpDefaultSlider();

    this.shouldRenderCarousel.set(true);
  }

  #setUpWithThumbnailSlider() {
    this.slider = new KeenSlider(this.sliderMainProductRef.nativeElement);
    this.thumbnailSlider = new KeenSlider(
      this.thumbnailRef.nativeElement,
      {
        initial: 0,
        slides: {
          perView: 1,
          spacing: 15,
        },
        breakpoints: {
          '(min-width: 450px)': {
            slides: { perView: 2, spacing: 15 },
          },
          '(min-width: 850px)': {
            slides: { perView: 4, spacing: 15 },
          },
        },
      },
      [ThumbnailPlugin(this.slider)]
    );
  }

  #setUpProducerProductsSlider() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: false,
      mode: 'free',
      slides: {
        perView: 'auto',
        spacing: 10,
      }
    });
  }

  #setUpDefaultSlider() {

    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: false,
      mode: 'free-snap',
      slides: {
        perView: 'auto',
        spacing: 10,
      },
    });

  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
    if (this.thumbnailSlider) this.thumbnailSlider.destroy();
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  whatsInside(event: Event) {
    this.onWhatsInsideClick.emit(event);
  }

  addAllProducts(event: any) {
    for (const element of this.productsComponent()) {
      element.addOrSubscribeFromFatherComponent(event);
    }
  }

  //#endregion
}
